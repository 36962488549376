$enable-responsive-font-sizes: true;

@import '~bootstrap';
@import '~slick-carousel/slick/slick';
@import '../stylesheets/layout';
@import '../stylesheets/variables';
@import '../stylesheets/agenda';
@import '../stylesheets/fonts/satoshi';

html, body {
  height: 100vh;
}

body {
  flex-direction: column;
  font-family: 'Satoshi', Helvetica, Geneva, 'MS Sans Serif', sans-serif;
}

.content-wrapper {
  flex: 1 0 auto;
}

.copyrights {
  flex-shrink: 0;
}


body {
  background-color: $light-color;
  background-position-y: 3rem !important;

  &.sign-in {
    background-color: $body-background-color;

    header {
      border-bottom: 1px solid $light-border-color;
    }

    main {
      min-height: 600px;
      align-items: center;
    }

    footer {
      border-top: 1px solid $light-border-color;
    }
  }

  &.email-form {
    main {
      display: flex;
    }
  }

  input.form-control {
    border-radius: 2px;
    border-color: #ddd;
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: inherit;
  }

  &.text-black {
    color: #000;
  }
}

#main-content {
  background-repeat: no-repeat;
  background-position: top right;
}

.title {
  @include title_font;
  font-weight: 600;
}

.divider {
  @include title_divider;
}

.sign_in, .sign_up {
  &--wrapper {
    background-color: $light-color;
    padding: 2rem;
    max-width: 35rem;
    margin: 0 auto;

    h3 {
      font-size: 28px;
    }

    .badge.section-dedicated {
      color: #fff;
      background-color: #1B3866;
    }
  }

  &--divider {
    @include title_divider;
    margin-left: auto;
    margin-right: auto;
  }
}

.sign_up--wrapper {
  max-width: auto;

  .form-check-input.is-invalid[checked="checked"] ~ .form-check-label {
    color: $dark-color;
  }
}

.forbidden-section {
  .message {
    font-weight: 600;
    line-height: 1.2;
    color: #111;
    font-size: 20px;
  }
  .notice {
    font-size: 15px;
    color: #5f5f5f;
  }
}

.full-container {
  width: 100%;
}

@include media-breakpoint-up(xl) {
  .container {
    max-width: 1210px;
  }
}

.light-bg {
  background-color: $light-color;
}

.light-grey-bg {
  background-color: $light-grey;
}

.dark-bg {
  background-color: $body-background-color;
  color: $light-color;
}

.very-dark-bg {
  background-color: #000; //$navy-dark-color;
  color: $light-color;
}

.blue-bg {
  background-color: $body-background-color;
}

.gray-bg {
  background-color: #eee;
}
.text-dark {
  color: $black;
}

.sponsor {
  width: 100%;
  color: $dark-color;
  align-items: stretch;
  min-height: 8.8rem;

  &.with-bg {
    background-color: #150F38;
  }

  &--page {
    position: relative;
  }

  &--background {
    z-index: -1;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
  }

  &--link {
    display: block;
    text-decoration: none;
    color: #FFC400;

    &:hover {
      color: #FFC400;
      text-decoration: underline;
    }
  }

  &--logo {
    min-width: 60%;
    max-width: 70%;
  }

  &--logo-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &:hover {
      text-decoration: none;
    }

    &.rank-0, &.rank-1 {
      height: 6.5rem;
    }

    &.rank-2 {
      height: 4.5rem;
    }

    &.rank-3, &.rank-5 {
      height: 3.8rem;
    }
  }

  img {
    max-width: 98%;
    max-height: 100%;
    height: auto;
  }
}

.sponsor-booth {
  box-shadow: 0 0 18px -2px rgba(0, 0, 0, 0.55);
  align-items: stretch;
  min-height: 14rem;
  position: relative;

  &--label {
    z-index: 1;
    position: absolute;
    background-color: $light-color;
    text-transform: uppercase;
    letter-spacing: 2px;
    top: -10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    padding: 2px 6px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
  }

  .sponsor {
    &--logo {
      max-width: 100%;
      height: auto;
    }

    &--logo-link {
      &[class*="rank-"] {
        height: auto;
      }
    }
  }

  &_booth {
    position: relative;

    .play-booth {
      position: absolute;
      top: 15%;
      left: 27%;
      z-index: 10;
      transition: all .2s ease-in-out;
      cursor: pointer;
      max-width: 40%;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .booth {
    max-width: 100%;

    @media only screen and (min-width: 992px) {
      max-width: 250px;
    }
  }
}

.big-rounded {
  border-radius: 1.5rem !important;
}

.congress-card {
  &.inactive {
    .card-image {
      opacity: 0.4;
    }
    .badge.section-dedicated {
      background-color: #a6a6a6;
    }

    .congress-title {
      a {
        color: #434343;
      }

      a:hover {
        text-decoration: underline;
        text-decoration-color: #434343;
        -moz-text-decoration-color: #434343;
      }
    }

    .btn-warning {
      background-color: #eee;
      border-color: #eee;
    }
    .btn-orange-border {
      border-color: #eee;
    }
    .btn {
      pointer-events: none;
    }
  }
}

.future {
  .congress-card {
    min-height: 332px;
  }
}

// faq
.accordion {
  & > .card {
    overflow: visible;
    border: 0px;
    border-top: 1px solid #dfdfdf;

    &:last-of-type {
      margin-bottom: 0.5rem !important;
      border-bottom: 1px solid #dfdfdf;
    }

    & > .card-header {
      margin-bottom: 0;
    }
  }
}

.faq {
  .card {
    font-size: 16px;
    color: #111;

    &-header {
      background-color: $light-color;
      font-size: 110%;
      cursor: pointer;
      border-bottom: 0;

      &:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        right: 1.1rem;
        top: 1.1rem;
        font-size: 1rem;
        content: "\f068";
      }

      &.collapsed {
        border-bottom: 0;

        &:after {
          content: "\f067";
        }
      }
    }
  }
}

.main-congresses {
  h2.events-header {
    font-size: 36px;
  }

  h4.events-header {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0px;

    a {
      color: #fff;
    }

    a:hover {
      color: #fff;
    }
  }

  .events-header {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    padding: 50px 0;
    text-align: center;
  }

  .card-header {
    border-radius: 2px;
  }
  .card {

    &-image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center 0;
      height: 9rem;
    }

    &-body {
      color: $dark-color;

      h4 {
        a {
          color: $dark-color;
        }
      }

      .pills {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          background-color: #eee;
          border-radius: 10px;
          font-size: 0.8rem;
          font-weight: 400;
          color: #434343;

          .fa-circle {
            font-size: 8px;
          }
        }
      }
    }
  }

  .no-events {
    p {
      text-align: center;
      font-size: 1.5em;
      margin: 0px;
    }
    hr {
      width: 15%;
      height: 2px;
      margin: 15px auto 45px auto;
      background-color: #384B69;
    }

    @include media-breakpoint-down(sm) {
      h2.events-header {
        padding-bottom: 10px;
      }
      p {
        font-size: 1em;
      }
      hr {
        margin: 0 auto 10px auto;
        width: 40%;
      }
      h4.events-header {
        font-size: 1em;
      }
    }
  }

  @media only screen and (min-width: 776px) {
    .card {
      &-image {
        width: 50%;
        min-height: 332px;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    h2.events-header {
      font-size: 20px;
      margin-bottom: 0px;
    }

    .events-header {
      font-weight: 500;
      font-size: 20px;
      padding: 30px 0;
    }

    .card-image {
      height: 12rem;
    }

    .congress-title {
      margin-bottom: 0px;
    }
  }

  .congress-card {
    .event-timeframe {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      color: #666;
    }

    .congress-title {
      &h3 {
        font-size: 36px;
        line-height: 36px;
      }

      &h4 {
        font-size: 24px;
        line-height: 26px;
      }

      a {
        font-weight: 400;
        color: #111;

        &:hover {
          text-decoration: underline;
          text-decoration-color: $main-blue;
          -webkit-text-decoration-color: $main-blue;
        }
      }
    }

    p {
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      padding: 5px 0 15px 0;
      margin-bottom: 0px;
      color: #111;
    }

    @include media-breakpoint-down(xs) {
      p {
        padding: 10px 0;
        font-size: 15px;
      }
    }

    .congress-mode {
      font-size: 14px;
      line-height: 16px;
      color: #6C6C6C;

      img {
        margin-right: 10px;
      }
    }
  }

  .past {
    .col-12.archival-event {
      padding-right: 12px;
      padding-left: 12px;
    }

    .card {
      border: none;

      &-body {
        border-bottom: 1px solid $body-background-color;
        border-radius: 2px;
        height: 12rem;

        @include media-breakpoint-down(sm) {
          height: auto;
          min-height: 8em;
        }
      }

      &-image {
        border-top: 1px solid $body-background-color;
        border-radius: 2px 2px 0 0;
        width: 100%;
        min-height: 214px;
      }
    }

    .congress-card {
      .records-link {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 44px;
        color: $main-blue;

        img {
          margin-right: 5px;
        }
      }
    }

    .year-archive {
      color: $main-blue;
      font-weight: 600;
      font-size: 20px;

      hr {
        width: 118px;
        height: 2px;
        margin-left: 22px;
        margin-right: auto;
        margin-top: 0.7rem;
        margin-bottom: 0;
        background-color:$main-blue;
      }
    }

    &:first-of-type {
      .year-archive {
        color: #fff;

        hr {
          background-color: #fff;
        }
      }
    }
    @include media-breakpoint-down(xs) {
      .year-archive {
        font-size: 18px;
      }

      .congress-title {
        font-size: 28px;
      }
    }
  }

  .future {
    .card {
      border: none;

      @include media-breakpoint-up(md) {
        &-image {
          border-radius: 2px 0 0 2px;
          min-width: 50%;
        }

        &-body {
          border-radius: 0 2px 2px 0;
        }
      }

      @include media-breakpoint-down(sm) {
        &-image {
          min-height: 287px;
          border-radius: 2px 2px 0 0;
        }

        &-body {
          border-radius: 0 0 2px 2px;
        }
      }

      &.congress-card {
        font-style: normal;
      }
    }

    .badge {
      float: left;
      clear: both;
      margin-left: 1em;
      margin-bottom: 0px;

      &:first-of-type {
        margin-top: 1em;
      }
    }

    .btn {
      font-weight: 500;
      font-size: 16px;
      padding: 0.75em 2.5em;
      margin-right: 1em;
      border-radius: 2px;
    }

    @include media-breakpoint-down(sm) {
      .btn {
        margin-right: 0px;
        width: 100%;
        margin-top: 1em;
      }
    }

    .inactive {
      p {
        color: #555;
      }

      .btn-blue {
        background-color: #DCDCDC;
      }

      .btn-blue-border {
        border-color: #DCDCDC;
        color: #DCDCDC;
      }

      .congress-title {
        a {
          color: #555;

          &:hover {
            text-decoration-color: #555;
            -webkit-text-decoration-color: #555;
          }
        }
      }
    }
  }
}

.new_congresses_users_register {
  .agreements, .congresses_users_register_representant_agreements {
    label {
      font-size: .8rem;
      color: #434343;
      font-weight: bold;
      small {
        font-weight: normal;
      }
    }
  }
  .congresses_users_register_agreements,
  .congresses_users_register_representant_agreements {
    legend {
      display: none;
    }

    .form-check {
      margin-bottom: 1rem
    }
  }
}

.disclaimer {
  font-size: .6rem;
}

aside.promotion {
  position: absolute;
  top: 15rem;
  right: 0;
}

.faq {
  .card-header {
    h6 {
      line-height: 140%;
    }
  }
}

.e404 {
  font-size: 20rem;
  font-weight: bold;
}

.sponsor--page {
  a {
    text-decoration: none;
  }

  .grey-box {
    background: #f3f3f3;
    margin: 15px 0;
    padding: 25px;

    .title {
      padding-bottom: 20px;
    }

    ul {
      list-style: none;
      font-weight: bold;
      font-size: 0.9rem;
      padding-left: 0px;
      margin-bottom: 0px;

      li {
        padding: 6px 0;

        i {
          color: $dark-blue;
          margin-right: 10px;
        }

        img {
          border: 1px solid $dark-blue;
          border-radius: 2px;
          margin-right: 10px;
        }

        em {
          font-weight: normal;
          color: $dark-grey;
          font-size: 0.8rem;
        }
      }
    }

    a {
      color: $dark-blue;
      word-break: break-all;

      &.btn {
        color: $light-color;
      }
    }
  }

  #sponsor_message {
    p {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    #new_congresses_sponsors_message {
      position: relative;
    }

    label.agreement {
      font-size: 0.8em;
      padding-top: 5px;
    }
  }
}

.indicator-layer {
  position: absolute;
  opacity: 0.5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../images/ajax-loader.gif') center no-repeat #eee;
}

.thank-you {
  h3 {
    font-size: 32px;
    line-height: 40px;
  }

  .congress-info {
    font-size: 24px;
    background: $light-color;
    color: #111;
    font-weight: 600;
    border-radius: 2px;
  }

  p {
    font-size: 16px;
    line-height: 26px;
  }
}

.navbar-toggler {
  color: $light-color
}

.btn-secondary {
  border-color: #A200FF;
  @include gradient(#8200FF, #A200FF);
}

.btn-secondary-border {
  border-color: #A200FF;
  color: #A200FF;

  &:hover {
    @include gradient(#8200FF, #A200FF);
    color: $light-color;
  }
}


.btn-orange {
  border-color: transparent;
  background-color: #ffc107;
  color: $dark-color;

  &:hover {
    border-color: #d39e00;
    background-color: #e0a800;
  }
}

.btn-orange-border {
  border-color: #ffc107;
  color: $light-color;
  &:hover {
    border-color: #d39e00;
  }
}

.btn-blue {
  border-color: transparent;
  background-color: $main-blue;
  color: #fff;
  &:hover {
    background-color: $main-blue-hover;
    color: #fff;
  }
}

.btn-blue-border {
  border-color: $main-blue;
  color: $main-blue;
  &:hover {
    border-color: $main-blue-hover;
    color: $main-blue-hover;
  }
}

.btn-yellow {
  border-color: transparent;
  background-color: #FFC70940;
}

.btn-outlined {
  border-color: #8200FF;
  color: #8200FF;
  transition: all 0.5s ease-in-out;

  &:hover {
    color: #fff;
    background-color: #8200FF;
  }
}

.btn, .badge {
  border-radius: 2px;
}


.badge {
  color: #fff;
  background-color: #1B3866;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  padding: 0px 5px;
  display: inline-block;

  &.section-dedicated, &.info-badge {
    background-color: #fff;
    color: #000;
  }
}

.alert {
  border-radius: 2px;
  text-align: center;

  &.alert-blue {
    margin-top: 1em;
    background-color: #1B3866;
    border-color: #1B3866;
    color: $light-color;
  }
}

.congress-card {
  .congress-title {
     a:hover {
      text-decoration: underline;
      text-decoration-color: $main-blue;
      -moz-text-decoration-color: $main-blue;
      text-decoration-thickness: 3px;
    }
  }

  .btn-orange-border {
    color: $dark-color;
  }
}

#partners-carousel {
  @mixin white-gradient {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  .slider {
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;

    &::before,
    &::after {
      @include white-gradient;
      content: "";
      height: 100%;
      position: absolute;
      width: 200px;
      z-index: 2;
    }

    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }

    &::before {
      left: 0;
      top: 0;
    }

    .slide-track {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .slide {
      height: 2.4rem;
      vertical-align: middle;
      display: flex !important;
      align-items: center;

      img {
        width: auto;
        max-height: 40px;
        margin: auto;
      }

      .img-fluid {
        max-width: 200px;
      }

      &.group {
        border-left: 2px solid $body-background-color;
      }
    }
  }
}

.carousel {
  white-space: nowrap;
  height: 150px;
  position: relative;

  &__items {
    height: 100%;
    position: relative;

    div {
      height: 100%;
      display: grid;
      gap: 1rem;
      justify-items: center;
      grid-template-rows: 80% 1fr;

      .badge {
        font-size: 0.8rem;
        background-color: white;
        box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
        font-weight: normal;
        color: black;
        padding: 0 1rem;
        justify-self: center;
      }

      a, div {
        height: unset !important;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

#player-countdown {
  @media only screen and (min-width: 776px) {
    min-height: 40rem;
  }
  min-height: 20rem;
  color: $light-color;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  font-weight: 500;

  h5 {
    font-size: 32px;
  }

  .countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 4rem;

    .days, .hours, .minutes {
      text-align: center;
      text-transform: uppercase;
      font-size: 80px;
      font-weight: 500;
    }

    span {
      display: block;
      font-size: 20px;
    }

    .colon {
      text-align: center;
      margin: -0.5em 0.3em 0 0.3em;
      font-size: 80px;
    }
  }
}

.speaker--avatar {
  width: 40px;
  height: auto;
}

.with-gradient {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .3));
}

.marketplace {
  &--wrapper {
    position: relative;
  }

  &--product {
    width: 90vw !important;

    @media only screen and (min-width: 600px) {
      width: 220px !important;
    }
    text-align: center;

    .image-wrapper {
      width: 100%;
      text-align: center;
      height: 220px;
      overflow: hidden;
    }

    h5 {
      line-height: 1.5rem;
      height: 3rem;
      overflow: hidden;
    }
  }

  &--arrows {
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(200%);
    z-index: 50;

    .slick-arrow {
      padding: 0.5rem;
      background-color: $gray-100;
    }
  }

  .product-box {
    padding: 25px 10px;

    a {
      color: $main-blue;
      font-size: 18px;

      i {
        font-size: 32px;
        display: block;
        margin-bottom: 25px;
      }

      &.btn-secondary-border {
        padding: 5px 3rem;
      }

      &.btn-secondary-border:hover {
        color: $light-color;
      }
    }

    .product-title {
      margin: 20px 0;
      height: 3em;
      line-height: 1.5em;
      overflow: hidden;
    }

    &.show-more {
      @media only screen and (max-width: 992px) {
        padding-top: 10%;
        width: 90vw !important;
      }
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

#transmission-icons {
  h5 {
    font-weight: 400;
  }

  .icon-wrapper {
    min-width: 3rem;
    min-height: 3rem;
  }
}

.products {
  position: relative;

  &--wrapper {
    width: 185px !important;
    text-align: center;
    min-height: 220px;
  }

  &--thumbnail {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  &--link {
    font-size: 80%;
    color: $dark-color;
  }

  &--arrows {
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
  }
}

.attachments {
  position: relative;

  &--wrapper {
    width: 185px !important;
    text-align: center;
    padding: 0;
    margin: 0 1.2rem;
  }

  &--thumbnail {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  &--link {
    font-size: 80%;
    color: $dark-color;
  }

  &--arrows {
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
  }
}

.company-links {
  position: relative;

  &--arrows {
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
  }

  .link-icon {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    text-align: center;

    .wrapper {
      display: inline-block;
      width: 30px;
      line-height: 30px;
      border-radius: 30px;
      background-color: $main-blue;
    }
  }
}

.pointer {
  cursor: pointer;
}

.gray-text {
  color: $dark-grey;
}

.light-text {
  color: $light-color;
}

.search_results {
  ul {
    list-style-type: none;
    padding: 0;
    border: 1px solid $gray-100;

    li {
      padding: 0.2rem 0.6rem;
      cursor: pointer;

      &:hover {
        background-color: $gray-100;
      }
    }
  }
}

.current-appointment {
  border-image: linear-gradient(
      to bottom,
      #A200FF,
      #8200FF
  ) 1 100%;
  border-width: 5px;
  border-style: solid;
  min-height: 5rem;
  border-right-width: 0;
  border-top-width: 0;

  a.js-scroll-trigger {
    color: inherit;
    display: block;
    height: 100%;

    strong {
      color: $black
    }
  }
}

@media only screen and (min-width: 992px) {
  #icons-wrapper h3.light-text {
    color: $dark-color;
  }
}

.links {
  &--wrapper {
    width: 14rem;
  }
}

#sas_null {
  display: none;
}

#notice-container, #notice-container .btn {
  font-family: Roboto, Helvetica, Geneva, 'MS Sans Serif', sans-serif;
  font-weight: 400;
  font-size: 15px;
}

@media only screen and (max-width: 768px) {
  #notice-container {
    font-size: 13px;
  }
  #notice-container .btn {
    width: 100%;
    font-size: 13px;
  }
}

.links-slick {
  .links--wrapper {
    align-self: stretch;
    min-height: 10rem;
    padding: 0 2rem;
    width: 30%;
    position: relative;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 10px;
    text-align: center;

    .description {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    a {
      width: 10rem;
      margin: 0 auto 1.5rem;
    }
  }

  .slick-slide {
    margin: .5rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1) !important;
    padding: 2rem;
    text-align: center;
    min-height: 13rem;

    .links--wrapper {
      min-height: auto;
      padding: 0;
      height: 100%;
      margin: auto;

      .description {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
      a {
        width: inherit;
        margin: 0;
      }
    }

    .link-icon {
      top: -30px;
    }

    .shadow-sm {
      box-shadow: none !important;
    }
  }
}

.slick-list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .slick-track {
    display: flex;
    flex-direction: row;
    align-items: center;

    .slick-slide {
      align-self: stretch;
      height: auto;

      &>div {
        display: flex;
        align-items: stretch;
        height: 100%;
      }
    }
  }
}

.sticky-footer {
  @include media-breakpoint-up(lg) {
    display: none;
  }
  z-index: 1;
  display: block;
  position: fixed;
  bottom: 2px;
  left: 3px;
  right: 3px;
  height: 3.5rem;
  color: $white;
  text-align: center;
  padding-top: 1rem;
  border-bottom: 1px solid $white;
  @include gradient(#8200FF, #A200FF);

  a {
    color: $white;
    display: block;
    height: 100%;
    width: 100%;
  }
}

@include media-breakpoint-down(md) {
  footer {
    padding-bottom: 3.5rem;
  }
}
