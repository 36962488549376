@import './variables';

body {
  height: 100%;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  font-family: 'Satoshi', Helvetica, Geneva, 'MS Sans Serif', sans-serif;
  padding-top: 5rem;

  &.events {
    padding-top: 0;
  }
}

@include media-breakpoint-down(xs) {
  body.bright-layout {
    padding-top: 5rem;
  }
  header.bright-theme {
    height: 5rem;
  }
}

header {
  background-color: $header-color;
  height: 3.2rem;
  color: $light-text-color;

  a {
    color: $light-text-color;

    &:hover {
      color: #FFC400;
      text-decoration: none;
    }

    &.active {
      color: #FFC400;
    }
  }

  .logo {
    width: 10rem;
  }

  &.bright-theme {
    background-color: $light-color;
    height: auto;

    nav {
      line-height: 80px;

      .navbar-toggler {
        color: #333;
      }

      @include media-breakpoint-down(md) {
        .navbar-collapse {
          margin-left: -15px;
          margin-right: -15px;
        }
      }

      ul.navbar-nav {
        background-color: $light-color;

        li {
          font-weight: 500;
          font-size: 16px;
          text-transform: none;

          a {
            color: #333;
            border-bottom: 2px solid $light-color;
            margin-top: -2px;
            display: block;

            &:hover {
              color: #333;
              text-decoration: none;
              border-color: $main-blue;
            }

            &.active {
              border-color: $main-blue;
            }
          }
        }

        @include media-breakpoint-down(md) {
          li {
            font-weight: 400;
            font-size: 18px;
            text-align: center;

            a {
              border-top: 1px solid #F0F0F0;
              border-bottom: 1px;
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}

footer {
  background-color: $footer-color;
  color: $light-text-color;

  a {
    color: $light-color;
    text-decoration: none;
    font-style: normal;
  }

  h5 {
    @include title_font;
    text-transform: uppercase;
    font-size: 1.3rem;
    display: inline-block;
    padding: 0 3rem .3rem 0;
  }

  h6 {
    font-size: 90%;
  }

  &.light-theme {
    background-color: $main-blue;
    font-size: 16px;
    line-height: 28px;

    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 28px;
      letter-spacing: 0.5em;
      background: $main-blue;
      display: table;
      margin: -14px auto 0px auto;
      padding: 0 25px;
    }

    h6 {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
    }

    a:hover {
      color: #fff;
    }

    .partners {
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      text-align: center;

      h4 {
        text-transform: uppercase;
      }
    }

    .copyrights {
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;

      a {
        text-decoration: none;
      }
    }

    @include media-breakpoint-down(md) {
      address {
        margin-bottom: 2rem;
      }
      .copyrights {
        text-align: center;
      }
      .logo {
        text-align: center;
      }
    }
  }
}

.copyrights {
  a {
    color: $light-color;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 500px) {
  .chat {
    overflow: scroll;
  }
}

@media only screen and (min-width: 576px) {
  body {
    padding-top: 5rem;
  }

  header {
    height: 5rem;

    nav {
      .navbar-nav {
        font-size: 80%;
      }
    }

    .logo {
      width: 15rem;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .navbar-nav {
    font-size: 80%;
  }
}

// ---------------------------- EVENT PAGE ----------------------------
:root {
  --primary-color: #0C2041;
  --secondary-color: #00A8D3;
  --secondary-100-color: #00CBFF;
  --light-color: #ffffff;
  --dark-color: #001B46;
}

.events {
  .header .container {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
  }

  .header {
    background-repeat: no-repeat;

    nav {
      a {
        text-decoration: none;
        color: #fff;
      }
    }
  }

  .primary {
    background-color: var(--primary-color);
    color: #fff;

    &-text {
      color: var(--primary-color);
    }
  }

  .secondary {
    background-color: var(--secondary-color);
    color: #fff;

    &-text {
      color: var(--secondary-color);
    }
  }

  .featured {
    background-color: var(--light-color);
    color: var(--dark-color);
  }

  .promotional {
    background-color: #FFC400;
  }

  .light {
    background-color: #fff;
  }

  .with-arrows {
    li {
      position: relative;
      padding-inline-start: 1.3ch;
      margin-left: 30px;
      margin-bottom: 20px;
    }

    li::before {
      content: url('../images/arrow-bullet-sm.svg');
      display: inline-block;
      left: -17px;
      top: -1px;
      position: absolute;

      @include media-breakpoint-up(md) {
        content: url('../images/arrow-bullet.svg');
        left: -20px;
        top: -2px;
      }
    }
  }

  .summary {
    .item {
      min-width: 30%;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
  }

  .bolder {
    font-weight: 700;
  }

  .medium {
    font-weight: 400;
  }

  .text {
    &-xs {
      font-size: 0.9rem;
      line-height: 1.2rem;
    }

    &-base {
      font-size: 1rem;
      line-height: 1.4rem;
    }

    &-l {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    &-xl {
      font-size: 1.25rem;
      line-height: 1.6rem;
    }

    &-xxl {
      font-size: 1.5rem;
      line-height: 1.9rem;
    }

    &-xxxl {
      font-size: 3.125rem;
      line-height: 3.5rem;
    }

    @include media-breakpoint-up(md) {
      &-md-xl {
        font-size: 1.25rem;
        line-height: 1.6rem;
      }
    }
  }

  #congress-days-buttons {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    @include media-breakpoint-down(md) {
      .congress-day-links {
        display: inline-block;
        min-width: 40%;
      }
    }
  }

  .weight {
    &-light {
      font-weight: 400;
    }

    &-base {
      font-weight: 500;
    }

    &-medium {
      font-weight: 700;
    }
  }

  .m {
    &t-8, &my-8 {
      margin-top: 3rem;
    }

    &b-8, &my-8 {
      margin-bottom: 3rem;
    }
  }

  .link {
    &-primary {
      color: var(--primary-color);
    }

    &-secondary {
      color: var(--secondary-100-color);
    }

    &-light {
      color: var(--light-color);
    }

    &-dark {
      color: var(--dark-color);
    }
  }

  .btn {
    &-primary {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      background-image: none;
      color: #fff;
    }

    &-secondary {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);
      background-image: none;
      color: #fff;
    }

    &-light {
      background-color: var(--light-color);
      color: var(--dark-color);
    }
  }

  @include media-breakpoint-up(md) {
    br.responsive {
      display: none;
    }
  }

  .appointments {
    background-color: transparent;
  }

  .agenda--wrapper {
    height: auto;
    overflow-y: hidden;
    border-radius: 0;
  }

  .appointments {
    li.list-group-item {
      background-color: inherit;
      text-align: left;
      margin-left: 3px;
      padding-left: 60px;
      padding-top: 0;
      font-size: 18px;

      @include media-breakpoint-down(md) {
        padding: 0 !important;
      }

      .flex-row {
        margin-bottom: 40px;
        @include media-breakpoint-down(lg) {
          margin-bottom: 5px;
        }
      }

      .ico-thumbnail {
        overflow: hidden;
        width: 80px;
        height: 80px;
        padding: 15px;
        background-color: $main-blue;
      }

      span.appointment-timeframe {
        line-height: 1em;
        margin: -2px 0 5px 0;
        font-weight: 700;
      }

      i {
        color: $main-blue;
        margin-left: -46px;
        margin-top: 31px;
        position: absolute;
        @include media-breakpoint-down(lg) {
          margin-top: 0px;
        }
      }

      h5 {
        font-size: 20px;
        display: inline;
        font-weight: 700;
      }

      .appointment-details {
        font-weight: 700;
        margin-left: 20px;
        @include media-breakpoint-down(md) {
          margin-left: 0 !important;
        }

        .speaker-details {
          font-weight: 400;
          color: #C8C8C8;
          text-transform: uppercase;
          font-size: 15px;
        }
      }
    }
  }

  .carousel {
    height: 220px;

    &__items {
      height: 90%;
    }
  }

  .sponsors {
    border: 1px solid #E2E2E2;
    border-left: 0;
    border-right: 0;
  }

  .sponsor--logo {
    max-height: 100px;
    max-width: 200px;
  }

  .certificate {
    height: 130px;

    @include media-breakpoint-up(md) {
      height: 200px;
    }
  }
}
