@import './variables';

.agenda--wrapper {
  height: 25rem;
  overflow-y: scroll;
  border-radius: 0.2rem;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $navy-dark-color;
  }

  &::-webkit-scrollbar-thumb {
    background: #2D284D;
    border-radius: 5px;
  }
}


tr.agenda {
  display: flex;
  flex-direction: column;
  padding: 0;

  &:nth-child(even) {
    background-color: #fafafa;
  }

  td {
    font-size: 1.2rem;

    .timeframe {
      font-weight: 300;
      color: #858585;
      white-space: nowrap;
    }

    em.author {
      font-size: 1rem;
      font-style: normal;
      color: #b2afc3;
      display: block;
    }
  }
}

.dark-bg {
  .agenda--wrapper {
    color: #fff;

    .badge {
      background-color: #00DCFF;
      text-transform: uppercase;
      color: $dark-color;
      text-align: center;
      border-radius: 0;
      margin-left: 4.4rem;
      @media only screen and (min-width: 992px) {
        margin-left: 7.6rem;
      }
    }
  }

  em.author {
    color: rgba(255,255,255, 0.73);
    font-style: normal;
  }
}
dl.agenda {
  border-bottom: 1px solid rgba(255,255,255, 0.38);
}


  dl.agenda {
    display: flex;
    flex-direction: row;

    dt {
      white-space: nowrap;
    }
  }

.past-congress-header {
  color: #fff;
}

.appointments-in-past {
  .appointments-by-day {
    .row.post-agenda {
      border-bottom: 1px solid #ccc;

      h6 {
        font-weight: bold;
      }
      .author {
        display: inline-block;
      }
    }

    .btn {
      white-space: nowrap;
    }
  }

  .btn-primary {
    border-color: #A200FF;
    @include gradient(#8200FF, #A200FF);
  }

  .btn-outline-primary {
    color: #A200FF;
    border-color: #A200FF;

    &:hover, &:active {
      color: #fff;
      border-color: #A200FF;
      @include gradient(#8200FF, #A200FF);
    }
  }

}

.related-video {
  a.video-thumb {
    background: no-repeat #000 center;
    min-height: 196px;

    i {
      font-size: 6rem;
      color: #fff;
      opacity: 0.5;
    }
  }

  a.video-thumb:hover {
    i {
      opacity: 0.9;
    }
  }

  h5 a {
    font-weight: 700;
    color: #000;
    font-size: smaller;
  }

  p {
    font-size: small;
  }
}


.appointments {
  background-color: #031C44;
  color: $light-color;

  li.list-group-item {
    background-color: inherit;
    text-align: left;
    margin-left: 3px;
    padding-left: 60px;
    padding-top: 0px;
    font-size: 18px;

    .flex-row {
      margin-bottom: 40px;
      @include media-breakpoint-down(lg) {
        margin-bottom: 5px;
      }
    }

    .ico-thumbnail {
      overflow: hidden;
      width: 80px;
      height: 80px;
      padding: 15px;
      background-color: $main-blue;
    }
    span.appointment-timeframe {
      line-height: 1em;
      margin: -2px 0 5px 0;
      font-weight: 700;
    }
    i {
      color: $main-blue;
      margin-left: -46px;
      margin-top: 31px;
      position: absolute;
      @include media-breakpoint-down(lg) {
        margin-top: 0px;
      }
    }
    h5 {
      font-size: 20px;
      display: inline;
      font-weight: 700;
    }

    .appointment-details {
      font-weight: 700;
      margin-left: 20px;

      .speaker-details {
        font-weight: 400;
        color: #C8C8C8; 
        text-transform: uppercase;
        font-size: 15px;
      }
    }
  }
}

#congress.summary {
  .congress-header {
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .congress-details {
    min-height: 480px;
    color: #fff;

    h1 {
      font-size: 54px;
      margin: 1em 0 0.5em 0;
    }

    p {
      font-size: 30px;
      margin: 1em 0;
    }

    .info-badge {
      font-family: 'Roboto', sans-serif;
      padding: 0 1em;
    }

    span.event-date {
      font-size: 20px;
      font-weight: 700;
    }

    .btn-blue {
      font-size: 20px;
    }
  }
  .appointments {
    background-color: #031C44;
    color: $light-color;

    #sections-tab {
      a {
        font-weight: bold;
      }
    }
    ul.nav {
      border-bottom: 1px solid #746F8C;
      justify-content: center;
      li {
        a {
          color: $light-color;
          display: block;
          font-weight: 700;
          font-size: 17px;
          text-transform: uppercase;
          &.active {
            color: $main-blue;
          }
        }
      }
    }
  }
}
.overflow-hidden {
  overflow: hidden;
}
