@font-face {
  font-family: 'Satoshi';
  src: url('fonts/Satoshi-Light.eot') format('embedded-opentype'),
       url('fonts/Satoshi-Light.woff') format('woff'),
       url('fonts/Satoshi-Light.woff2') format('woff2'),
       url('fonts/Satoshi-Light.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Satoshi';
  src: url('fonts/Satoshi-Regular.eot') format('embedded-opentype'),
       url('fonts/Satoshi-Regular.woff') format('woff'),
       url('fonts/Satoshi-Regular.woff2') format('woff2'),
       url('fonts/Satoshi-Regular.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Satoshi';
  src: url('fonts/Satoshi-Medium.eot') format('embedded-opentype'),
       url('fonts/Satoshi-Medium.woff') format('woff'),
       url('fonts/Satoshi-Medium.woff2') format('woff2'),
       url('fonts/Satoshi-Medium.ttf') format('truetype');
  font-weight: 700;
}
